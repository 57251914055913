import React, {useContext, useState} from 'react'
import Layout from "../components/layout"
import CartContext from '../context/CartContext'
import Img from "gatsby-image"
import {slugify} from '../helpers'
import {Link} from 'gatsby'
import {renderToString} from 'react-dom/server'

const Cart = (props) => {
  const {state, clearCart, removeItem, getTotalValue} = useContext(CartContext)
  console.log(state)
  const [sent, setSent] = useState(false);

  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
    phone_number: "",
    address: "",
    city: "Tirane"
  });
  const cities = [
    'Tirane',
    'Belsh',
    'Berat',
    'Bulqize',
    'Cerrik',
    'Delvine',
    'Devoll',
    'Diber',
    'Divjake',
    'Dropull',
    'Durres',
    'Elbasan',
    'Fier',
    'Finiq',
    'Fushe-Arrez',
    'Gjirokaster',
    'Gramsh',
    'Has',
    'Himare',
    'Kamez',
    'Kavaje',
    'Kelcyre',
    'Klos',
    'Kolonje',
    'Konispol',
    'Korce',
    'Kruje',
    'Kucove',
    'Kukes',
    'Kurbin',
    'Lezhe',
    'Libohove',
    'Librazhd',
    'Lushnje',
    'Malesi e Madhe',
    'Maliq',
    'Mallakaster',
    'Mat',
    'Memaliaj',
    'Mirdite',
    'Patos',
    'Peqin',
    'Permet',
    'Pogradec',
    'Polican',
    'Prrenjas',
    'Puke',
    'Pustec',
    'Roskovec',
    'Rrogozhine',
    'Sarande',
    'Selenice',
    'Shijak',
    'Shkoder',
    'Skrapar',
    'Tepelene',
    'Tropoje',
    'Ura Vajgurore',
    'Vau i Dejes',
    'Vlore',
    'Vore',
  ]
  const getCartWeight = () => {
    return state.cart.reduce((acc, curr) => {
      acc = acc + curr.quantity * curr.fullItem.weightInGrams;
      console.log(acc)
      return acc;
    }, 0) / 1000;
  };

  const getTransportCost = () => {
    if (formState.city === 'Tirane') {
      let initial = 200;
      let extraWeight = Math.ceil(getCartWeight() - 3);
      let extraFees = extraWeight * 30;
      return initial + extraFees;
    }
    let initial = 300;
    let extraWeight = Math.ceil(getCartWeight() - 3);
    let extraFees = extraWeight * 50;
    return initial + extraFees;
  };

  const getEmail = () => {
    return <div>
      <p>Emri: {formState.name}</p>
      <p>Email: {formState.email}</p>
      <p>Mesazhi: {formState.message}</p>
      <p>Telefon: {formState.phone_number}</p>
      <p>Adresa: {formState.address}</p>
      <p>Qyteti: {formState.city}</p>
      <p>Transporti: {getTransportCost()}</p>
      <table>
        {state.cart.map(item => <tr key={item.fullItem.name}>
            <td>{item.fullItem.name}</td>
            <td>{item.quantity} x {Math.round(item.price * 100, 2) / 10000} = {Math.round(item.price * item.quantity * 100, 2) / 10000}</td>
          </tr>
        )}
      </table>
    </div>
  };

  const formIsValid = () => {
    return formState.name.length > 0 &&
      formState.email.length > 0 &&
      formState.message.length > 0 &&
      formState.phone_number.length > 0 &&
      formState.address.length > 0 &&
      formState.city.length > 0
  };
  const onChange = (e) => {
    setFormState({...formState, [e.target.name]: e.target.value});
  };

  const submitForm = async (e) => {
    console.log(renderToString(getEmail()))
    e.preventDefault();
    try{
      const response = await fetch("/.netlify/functions/sendmail", {
        method: "POST",
        body: renderToString(getEmail()),
      });
      setSent(true);
      clearCart()
    } catch(e){
    }
  };

  return (
    <Layout headerData={props}>

      {sent &&
        <p className="text-center mt-16 mx-auto p-16 bg-brand-100 rounded-lg max-w-md">Porosia juaj u dergua, Dikush nga departamenti i shitjeve do ju kontaktoje per me shume informacion. Faleminderit! <br/> <strong>Yours faithfully.</strong></p>
      }

      {!sent && <div className="max-w-2xl mx-auto rounded mb-8 px-2">
        <div className="w-full flex items-center mt-8 md:mt-16 mb-8 rounded justify-between">
          <div className="flex">
            <p className="font-bold text-brand-800 mr-4">Shporta juaj</p>
          </div>
          <div className="flex">
            <button className="flex items-center text-center cursor-pointer px-4 py-1 rounded rounded-full text-gray-600 hover:text-red-400 text-sm uppercase mr-4"
                    onClick={(event) => clearCart()}>
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"/>
              </svg>
              <span className="hidden md:block">Boshatisni shporten</span>
            </button>
            <><span>{getTotalValue()} Lek | &nbsp;</span><span className="font-bold">{state.cart.length ? 'Porosia' : 'Shporta juaj eshte bosh'}</span></>
          </div>

        </div>
        <div className="w-full flex flex-col items-center ">
          {state.cart.map((item, key) =>
            <div
              className="w-full border-t border-l border-r last:border-b border-gray-300 flex flex-col md:flex-row items-center bg-white mx-6 md:mx-0 px-4" key={item.id}>
              <Link to={'/product/' + slugify(item.fullItem.name + " " + item.fullItem.id)}
                    className="flex flex-col md:flex-row items-center px-2 w-full">
                <Img className="m-2 w-48 md:w-24 rounded m-2" fluid={item.fullItem.photos[0].localFile.childImageSharp.fluid}/>
                <div>
                  <p>{item.fullItem.name}</p>
                  <p className="font-bold text-sm">{item.quantity} x {Math.round(item.price * 100, 2) / 10000}Lek = {Math.round(item.price * item.quantity * 100, 2) / 10000} Lek</p>
                </div>
              </Link>
              <div className="flex w-1/12">
                <div onClick={(event) => removeItem(item)} className="m-2 text-gray-300 hover:text-red-400 cursor-pointer">
                  <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                    <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"/>
                  </svg>
                </div>
              </div>
            </div>
          )}
        </div>

      </div>}

      {!sent && <div className={'container mx-auto flex items-center flex-col'}>
        <div>
          <p>Plotesoni te dhenat me poshte dhe dergoni porosine</p>
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="name">Emri</label>
          <input className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3" onChange={onChange} name="name" id="name" type="text" placeholder="Emri"/>
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="email">Email</label>
          <input className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3" onChange={onChange} name="email" id="email" type="email" placeholder="Email"/>
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="message">Mesazhi</label>
          <textarea className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3" onChange={onChange} name="message" id="message" placeholder="Mesazhi"/>
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="phone_number">Telefon</label>
          <input className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3" onChange={onChange} name="phone_number" id="phone_number" type="text" placeholder="Telefon"/>
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="address">Adresa</label>
          <textarea className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3" onChange={onChange} name="address" id="address" placeholder="Adresa"/>
        </div>
        <div className="w-full md:w-1/2 px-3 mb-4">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="city">Qyteti</label>
          <div className="relative">
            <select className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" onChange={onChange} name="city" id="city">
              {cities.map(c => <option key={c} value={c}>{c}</option>)}
            </select>
          </div>
        </div>
        <p className={'text-xl '}>Transporti per kete ngarkese do jete: {getTransportCost()} Lek te reja.</p>
        <button disabled={!formIsValid()} onClick={submitForm}
                className={`text-white px-3 py-2 rounded w-128 mt-4 mb-4 ${!formIsValid() ? 'bg-brand-200' : 'bg-brand-500'}`}>
          {!formIsValid() ? 'Ju lutem plotesoni te gjitha fushat' : 'Dergo porosine!'}
        </button>
      </div>}


    </Layout>
  )
}

export default Cart